.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.BelowText {
  font-size: 1.6rem;
  font-family: 'Inter',sans-serif;
font-weight: 600;
}
.buttony {
  margin-top: 2rem;
}
.g-recaptcha {
  margin-top: 1px;
  margin-bottom: 1rem;
}
.Headertext {
  font-size: 3rem;
  background: #FF4400;
background: -webkit-linear-gradient(to right, #FF4400 0%, #FF7700 100%);
background: -moz-linear-gradient(to right, #FF4400 0%, #FF7700 100%);
background: linear-gradient(to right, #FF4400 0%, #FF7700 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-family: 'Inter',sans-serif;
font-weight: 600;
}

.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-address {
  position:flex;
  width: 1rem;
  margin-bottom: 20px;
}
